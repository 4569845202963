import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, ListGroup, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Button from "react-bootstrap/esm/Button";
//const BASE_URL = "http://127.0.0.1:8080";
//const BASE_URL = "https://globecompanionbackend.onrender.com";
const BASE_URL = "https://globe-comapnion.onrender.com";

function Dashboard(props) {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/fetchUser/${props.emailId}`);
        console.log("fetched data: ", response.data);
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch user data:", error.message);
      }
    };

    fetchData();
  }, [props.emailId]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/deleteUser/${id}`);
      toast.success('User deleted successfully', {
        autoClose: 3000
      });
      const updatedUsers = userData.filter(user => user._id !== id);
      setUserData(updatedUsers);
    } catch (error) {
      toast.error('Failed to delete User', {
        autoClose: 3000
      });
      console.error("Failed to delete user:", error.message);
    }
  };

  return (
    
    <div className="d-flex justify-content-center align-items-center mt-5">
    <ToastContainer />
      <div>
      {loading ? ( // Display loading spinner while data is being fetched
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>

      <h2>WELCOME {userData.userName}</h2>
        <Card className="mx-auto" style={{ width: '25rem' }}>
          {userData && (
            <>
              <Card.Img 
                variant="top" 
                src={userData.photo !==" " ? `data:image/jpeg;base64, ${userData.photo}` : `${process.env.PUBLIC_URL}/userImage/sample.jpg`}
                alt="User Photo" />
              <Card.Body>
                <Card.Title className="text-center">{userData.firstName} {userData.lastName}</Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item><span style={{ fontSize: '1.2em' }}>Email:</span> {userData.email}</ListGroup.Item>
                <ListGroup.Item><span style={{ fontSize: '1.2em' }}>Contact: </span>+{userData.contact}</ListGroup.Item>
              </ListGroup>
              <Card.Body className="d-flex justify-content-evenly mt-3">
                {props.userType === "user" && (
                  <Button as={Link} to={`/myDes/${userData.email}`} className="btn-dark">
                    My Destinations
                  </Button>
                )}
                <Button onClick={() => handleDelete(userData._id)} className="red-button">
                  Delete Account
                </Button>
              </Card.Body>
            </>
          )}
        </Card>
        </>
      )}
      </div>
    </div>
  );
}

export default Dashboard;

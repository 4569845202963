import React, { useState } from 'react';
import {Form, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function SearchBar() {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const capitalizedSearchTerm = searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1);
    
    const url = `/filter/${capitalizedSearchTerm}`;

    // Use history.push() to navigate without a full page reload
    navigate(url);
  };

  return (
    <Form className="d-flex"  onSubmit={handleSubmit}>
      <Form.Control
      type="text"
      placeholder="Search"
      className="mr-sm-2"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
      <Button variant="dark" type="submit">
      Submit
    </Button>
    </Form>
  );
}

export default SearchBar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Row, Card } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';
import "../css/style.css";
import { toast } from 'react-toastify';
import MessageModal from './MessageModal';
//const BASE_URL = "http://127.0.0.1:8080";
//const BASE_URL = "https://globecompanionbackend.onrender.com";
const BASE_URL = "https://globe-comapnion.onrender.com";

function FilteredDes(props) {
  const [filteredDestinations, setFilteredDestinations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {        
        
        const response = await axios.get(`${BASE_URL}/api/filterDes/${props.selectedOption}`);

        setFilteredDestinations(response.data);
      } catch (error) {
        console.error("Failed to make filter request:", error.message);
      }
    };
    fetchData();
  }, [props.selectedOption]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/deleteDes/${id}`);
      const updatedDestinations = filteredDestinations.filter(des => des._id !== id);
      setFilteredDestinations(updatedDestinations);
    } catch (error) {
      console.error("Failed to delete destination:", error.message);
    }
  }

  const handleContact = (id) => {
    if(props.isLoggedIn){
      setSelectedDestination(id);
      setShowModal(true);
    }
    else{
      toast.error('Login first', {
        autoClose: 3000
      });
    }
  };

  const handleSendMessage = async (message) => {
    console.log(`Sending message "${message}" to destination with ID: ${selectedDestination}, sent by ${props.emailId} `);
    try{
      await axios.post(`${BASE_URL}/api/sendMessage/${selectedDestination}/${props.emailId}/${message}`);
      toast.success('Message sent successfully', {
        autoClose: 3000
      });
      handleCloseModal();
    }catch(error){
      toast.error('Error in sending message', {
        autoClose: 3000
      });
    }
    
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDestination(null);
  };


  return (
    <div>
      <h2>{props.selectedOption}</h2>
      {filteredDestinations.length > 0 ? (
        <Row className="gx-4 gy-4" style={{ margin: "15px" }}>
          {filteredDestinations.map((des) => (
            <Col key={des._id} md={6}>
              <Card>
                <Row className="g-0">
                  <Col md={4}>
                    <Card.Img
                      variant="top"
                      src={`https://source.unsplash.com/1600x1100/?${encodeURIComponent(des.destination)}`}
                      alt="Destination Photo"
                      onError={(e) => {
                        console.log("error");
                      }}
                    />
                  </Col>
                  <Col md={8}>
                    <Card.Body>
                      <Card.Title  style={{textAlign:"center", fontSize:"1.5em"}}>{des.destination}</Card.Title>
                      <Card.Text>
                        <span style={{ fontSize: '1.2em' }}>Detail:</span> {des.detail}
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Duration:</span> {des.day}D / {des.night}N
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Date:</span> {new Date(des.date).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Mode of Convenience:</span> {des.modeOfConv}
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Group Size:</span> {des.groupSize}
                        <br /> 
                        <span style={{ fontSize: '1.2em' }}>Budget:</span> {des.budget}/- Rs
                      </Card.Text>
                      <Button onClick={() => handleContact(des._id)}  className="btn-dark">
                        Connect
                      </Button>
                      {props.userType === "user" && (
                        <Button onClick={() => handleDelete(des._id)} className="red-button">
                          Delete Destination
                        </Button>
                      )}
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <p>No destinations found for the selected option.</p>
      )}

      <MessageModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleSendMessage={handleSendMessage}
      />
    </div>
  );
}

export default FilteredDes;

import {Button, Form, Spinner} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../css/style.css";
import axios from "axios";
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OTPInput from 'otp-input-react'; 
import {CgSpinner} from "react-icons/cg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
//const BASE_URL = "http://127.0.0.1:8080";
//const BASE_URL = "https://globecompanionbackend.onrender.com";
const BASE_URL = "https://globe-comapnion.onrender.com";

function RegComp() {

  const [data, setData] = useState({
      userType: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contact: "",
      userName:"",
      photo:""
  });
  
  const [error, setError] = useState('');
  const [otp, setOtp] = useState('');  
  const [otpGenerated, setOtpGenerated] = useState('');
  const [loading, setLoading] = useState('');

  const sendOtpToEmail = async () => {
    try {
      console.log("ok")
      const url = `${BASE_URL}/api/sendOtp`;
      console.log(data.email)
      const { data: res } = await axios.post(url, { email: data.email});
      console.log("ok2")
      setOtpGenerated(res.otp);
      toast.success('OTP sent successfully to your email', { autoClose: 3000 });
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Error sending OTP', { autoClose: 3000 });
    }
  };

  const verifyOtp = async () => {
    try {
      if (otpGenerated === otp) {
        toast.success('OTP verified successfully', { autoClose: 3000 });
      } else {
        toast.error('Incorrect OTP', { autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('Error verifying OTP', { autoClose: 3000 });
    }
  };

  const handleChange = (e) => {
    if (e.target.name !== 'photo') {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log("photo in submit: ", photo)
      if(!photo){
        setData({
          ...data,
          photo: " ",
        });
      }
      const url = `${BASE_URL}/api/users`;
      const { data: res } = await axios.post(url, data);
      toast.success('Registered successfully', {
        autoClose: 3000,
        onClose: () => {
          window.location = "/";
        }
      });
    } catch (error) {
      console.error('Error:', error);
      if (error.response.data.message !== '"Photo" is not allowed to be empty' && error.response && error.response.status >= 400 && error.response.status <= 500) {
        toast.error('Registration Failed', { autoClose: 3000 });
        setError(error.response.data.message);
      }
    }finally {
      setLoading(false);
    }
  };

  const [photo, setPhoto] = useState();

  const submitUpload = async (e) => {
    e.preventDefault();
    try {
      console.log("photo in reg: ", photo)
        if(photo){
        const formData = new FormData();
        formData.append('photo', photo);
        const result = await axios.post(`${BASE_URL}/api/upload/${data.email}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log("result in reg: ", result);
        let photoName = result.data.photoData;
        console.log("photoname in reg: ", photoName);
        //console.log("photoname in reg: ", result.data.photoName);
        setData({
          ...data,
          photo: result.data.photoName,
        });
        }
        toast.success('Image uploaded successfully', {
          autoClose: 3000
        });
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        toast.error('Image uplodation failed', { autoClose: 3000 });
        setError(error.response.data.message);
      }
    }
  };

  const handleUpload = async (e) => {
    setPhoto(e.target.files[0]);
  };

  return (
    <div  style={{margin:"15px"}}>
      <h2>REGISTER</h2>

      <ToastContainer />
      {loading ? ( // Display loading spinner while data is being fetched
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) :
      <>
      <Form className="Profile" onSubmit={handleSubmit}>
        {/* userType */}
        <Form.Group className="mb-3" controlId="formBasicUserType">
          <Form.Label>Register as</Form.Label>
          <Form.Select
            aria-label="Default select example"
            required
            name="userType"
            onChange={handleChange}
          >
            <option value="">Select user type</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </Form.Select>
        </Form.Group>

        {/* firstName */}
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First name"
            name="firstName"
            onChange={handleChange}
            value={data.firstName}
          />
        </Form.Group>

        {/* lastName */}
        <Form.Group className="mb-3" controlId="formBasicLastName">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            name="lastName"
            onChange={handleChange}
            value={data.lastName}
          />
        </Form.Group>

        {/* email */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            onChange={handleChange}
            value={data.email}
            required
          />
          <Button onClick={sendOtpToEmail} style={{marginTop:"0.5rem"}} className='btn-dark'>
            <span>Send OTP to Email</span>
          </Button>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicOTP">
          <Form.Label>OTP</Form.Label>
          <OTPInput
            value={otp}
            onChange={setOtp}
            OTPLength={6}
            otptype="number"
            disabled={false}
            autoFocus
          ></OTPInput>
          <Button onClick={verifyOtp} style={{marginTop:"0.5rem"}} className='btn-dark'>
            <CgSpinner size={20} className="mt-1 animate-spin" />
            <span>Verify OTP</span>
          </Button>
        </Form.Group>

        {/* pass */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            value={data.password}
            required
          />
        </Form.Group>

        
<Form.Group className="mb-3" controlId="formBasicContactUpper">
  <Form.Label>Contact No.</Form.Label>
  <PhoneInput
    country={'in'}
    value={data.contact}
    onChange={(value) => {
      handleChange({ target: { name: 'contact', value } });
    }}
    required
    placeholder='Enter contact no.'
  />
</Form.Group>

        <Form.Group className="mb-3" controlId="formBasicUserName">
          <Form.Label>User Name</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="User Name" 
            name="userName"
            onChange={handleChange}
            value={data.userName}
            required
          />
        </Form.Group>

        <Form.Group className="position-relative mb-3" controlId="formBasicProfilePicture">
          <Form.Label>Profile Picture</Form.Label>
          <input type ="file" accept='image/*' onChange={handleUpload}/>
          <Button onClick={submitUpload} style={{marginLeft:"0.5rem"}} className='btn-dark'>
            <span>Upload</span>
          </Button>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Agree to terms and conditions" />
        </Form.Group>

        {error && <div style={{color:"red"}}>{error}</div>}
        
        <Button variant="primary" type="submit" className='btn-dark'>
          Register
        </Button>
      </Form>
      <p>Already have an account? <Link to="/login">Login</Link></p>
      </>
      }
      </div>
  );
}

export default RegComp;

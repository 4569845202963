import React from 'react';

function Features() {
  return (
    <div style={{margin:"15px"}}>
        <h2 className="pb-2 border-bottom  px-4 py-5">OUR FEATURES</h2>
      <div className="row featurette">
        <div className="col-md-7 align-self-center">
          <h2 className="featurette-heading fw-normal lh-1">
          Discover Your <span className="text-body-secondary"> Perfect Adventure Sidekick</span>
          </h2>
          <p className="lead">Unleash the thrill of spontaneous journeys! Connect with verified users who share your passion. Find your ideal travel companion and embark on unforgettable adventures together.</p>
        </div>
        <div className="col-md-5" style={{paddingLeft:"100px", paddingTop:"30px"}}>
          <img
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
            width="500"
            height="500"
            src="https://img.freepik.com/premium-photo/close-up-compass-paper-map-guide-travel-adventure-lifestyle-concept-planning-road-trip-new-discover-traveler-people-trip-destination_425263-5567.jpg?w=5000"
            role="img"
            aria-label="Placeholder: 500x500"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
          </img>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-7 order-md-2 align-self-center">
          <h2 className="featurette-heading fw-normal lh-1">
          Seamless Planning, <span className="text-body-secondary">Seamless Fun</span>
          </h2>
          <p className="lead">
          Say goodbye to solo travel blues! Plan your trips effortlessly and synchronize schedules with ease. Our platform ensures a hassle-free experience, so you can focus on creating lasting memories.
          </p>
        </div>
        <div className="col-md-5 order-md-1">
        <img
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
            width="500"
            height="500"
            src="https://img.freepik.com/free-photo/people-planning-trip-with-map-full-shot_23-2148925826.jpg?w=826&t=st=1700590820~exp=1700591420~hmac=8e853991d34af9dd9d0c930a2bcb4b183f3900c1b788139333cce62454b33ddd"
            role="img"
            aria-label="Placeholder: 500x500"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
          </img>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-7 align-self-center">
          <h2 className="featurette-heading fw-normal lh-1">
          Privacy at Its Finest, <span className="text-body-secondary">Fun at Its Peak</span>
          </h2>
          <p className="lead">Your privacy matters! Enjoy the excitement of exploring with confidence. Connect securely with like-minded explorers, and let the journey unfold, knowing your privacy is our top priority.</p>
        </div>
        <div className="col-md-5"  style={{paddingLeft:"100px", paddingBottom:"10px"}}>
        <img
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
            width="500"
            height="500"
            src="https://img.freepik.com/premium-photo/15-minute-city-isometric-map-people-transport-security-cameras-drone-bot-police-border_587981-4216.jpg?w=740"
            role="img"
            aria-label="Placeholder: 500x500"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
          </img>
        </div>

        <hr className="featurette-divider" />
      </div>
    </div>
  );
}

export default Features;

import React from "react";
import { Link } from 'react-router-dom';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { FaUser, FaUsers, FaGlobe, FaComment } from 'react-icons/fa'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from "./SearchBar";

function Header(props) {
  console.log("loggedin: ", props.isLoggedIn)
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("emailId");
    localStorage.removeItem("userType");
    localStorage.removeItem("userContact");
    toast.success('Logged out successfully', { autoClose: 3000 });
    window.location="/";
  };

  const profileDropdown = () => {

    if (props.isLoggedIn) {
      if(props.userType === "admin"){
        return (
              <>         
                <NavDropdown.Item as={Link} to="/dashboard">Dashboard</NavDropdown.Item>    
                <NavDropdown.Item as={Link} to="/allUser">Remove Account</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
              </>
        );
      }

      else{
        return (
          <>         
            <NavDropdown.Item as={Link} to="/dashboard">Dashboard</NavDropdown.Item>   
            <NavDropdown.Item as={Link} to={`/myDes/${props.emailId}`}>My Destination</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/addDestination">Add Destination</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={`/myDes/${props.emailId}`}>Delete Destination</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
          </>
        );
      }
    } else{
      return (<>
            <NavDropdown.Item as={Link} to="/register">Register</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/login">Login</NavDropdown.Item>
           </>
      );
    }
  };

  const backgroundStyle = {
    backgroundImage: 'url("https://img.freepik.com/premium-photo/simple-abstract-geometric-pattern-background-color-background-website-design-decoration_86390-7411.jpg?w=1000")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    marginBottom: "10px"
  };

  return (
    <>
      <ToastContainer />
      <Navbar sticky="top" expand="lg" style={backgroundStyle}>
        
        <Container fluid>
          <Link to="/">
            <img src="https://a0.anyrgb.com/pngimg/484/1582/world-tourism-day-world-travel-traveling-travel-logo-traveler-global-warming-travel-around-the-world-travel-bag-microsoft-powerpoint-transportation.png" alt="logo" style={{ height: "80px", width: "80px" }} 
              
            />
          </Link>
          <Navbar.Brand as={Link} to="/" style={{ fontSize: "2rem", paddingLeft:"1rem" }}>GlobeCompanions</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />          
          
          <Navbar.Collapse id="responsive-navbar-nav">          
            <Nav className="me-auto">
              <></>
            </Nav>
            <Nav>
            <Link to="/allUser" className="btn"  style={{ fontSize: "1rem", paddingLeft:"0", width:"100px" }}>
              <FaUsers /> All Users
            </Link>
            <Link to="/allDes" className="btn"  style={{ fontSize: "1rem", paddingLeft:"0", width:"145px"  }}>
              <FaGlobe /> All Destinations
            </Link>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title={<><FaUser /> Profile</>}
                menuVariant="light"
                align="end"
              >
                {profileDropdown()}
              </NavDropdown>
            </Nav>
            <SearchBar />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;

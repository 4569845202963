import React from "react";
import {Card, Button, ListGroup, Row, Col} from 'react-bootstrap';
import "../css/style.css";

function DestinationCard(props) {
  return(
    <Card className="vibrant-card">
                <Row className="g-0">
                  <Col md={4}>
                    <Card.Img
                      variant="top"
                      src={`https://source.unsplash.com/1600x1100/?${encodeURIComponent(props.destination)}`}
                      alt="Destination Photo"
                      onError={(e) => {
                        console.log('error');
                      }}
                    />
                  </Col>
                  <Col md={8}>
                    <Card.Body>
                      <Card.Title style={{textAlign:"center", fontSize:"1.5rem"}}>{props.destination}</Card.Title>
                      <Card.Text>
                        <span style={{ fontSize: '1.2em' }}>Detail:</span> {props.detail}
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Duration:</span> {props.day}D / {props.night}N
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Date:</span> {new Date(props.date).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Mode of Convenience:</span> {props.modeOfConv}
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Group Size:</span> {props.groupSize}
                        <br /> 
                        <span style={{ fontSize: '1.2em' }}>Budget:</span> {props.budget}/- Rs
                      </Card.Text>
                      
                      { (props.showContact) &&
                      <Button onClick={props.handleContact}  className="btn-dark">
                        Connect
                      </Button>
                      }

                      {props.showDelete && (
                        <Button variant="danger" onClick={props.handleDelete}>
                          Delete
                        </Button>
                      )}
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
  );
}

export default DestinationCard;
import {Button, Form, Spinner} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from "react";
import axios from "axios";
import "../css/style.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//const BASE_URL = "http://127.0.0.1:8080";
//const BASE_URL = "https://globecompanionbackend.onrender.com";
const BASE_URL = "https://globe-comapnion.onrender.com";

function LoginComp() {
  const [data, setData] = useState({ email: "", password: "" });
	const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
    setLoading(true);
		try {
			const url = `${BASE_URL}/api/auth`;
			const { data: res } = await axios.post(url, data);
			localStorage.setItem("token", res.data);
      
      console.log("in login")
      const response = await axios.get(`${BASE_URL}/api/fetchUser/${data.email}`);
      localStorage.setItem("userType", response.data.userType);
      localStorage.setItem("userContact", response.data.contact);
      localStorage.setItem("emailId", response.data.email );

			toast.success('Logged in successfully', {
        autoClose: 3000,
        onClose: () => {
          window.location = "/";
        },
      });
      
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			} 
		}finally {
      setLoading(false);
    }
	};

  return (
    <div  style={{margin:"15px"}}>
    <h2>LOGIN</h2>
    <ToastContainer />
    {loading ? ( // Display loading spinner while data is being fetched
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) :
      <>
    <Form  className="Profile" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control        
          type="email"
					placeholder="Email"
					name="email"
					onChange={handleChange}
					value={data.email}
					required
        />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control 
          type="password"
				  placeholder="Password"
				  name="password"
				  onChange={handleChange}
				  value={data.password}
				  required
        />
      </Form.Group>
      
      {error && <div style={{color:"red"}}>{error}</div>}
      <Button variant="primary" type="submit" className='btn-dark'>
        Login
      </Button>
    </Form>
    <p>New User? <Link to="/register">Register</Link></p>
    <p><Link to="/forgotPass">Forgot Password?</Link></p>
    </>
    }
    </div>
  );
}

export default LoginComp;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Spinner } from 'react-bootstrap';
import "../css/style.css";
import DestinationCard from "../components/DestinationCard";
import { toast, ToastContainer } from 'react-toastify';
// const BASE_URL = "http://127.0.0.1:8080";
// const BASE_URL = "https://globecompanionbackend.onrender.com";
const BASE_URL = "https://globe-comapnion.onrender.com";

function MyDestination(props) {
    const [desData, setDesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const itemPerPage = 4;
  const [currentPage, setCurrentPage] = useState(0);
  const rows = desData.slice(
    currentPage*itemPerPage,
    currentPage + 1 * itemPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const numberOfPage = Math.ceil(desData.length / itemPerPage);
  const pageIndex = Array.from({length:numberOfPage}, (_, idx) => idx+1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/myDes/${props.desEmailId}`);
                console.log(response.data);
                setDesData(response.data);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error("Failed to make request:", error.message);
            }
        };
        fetchData();
    }, [props.desEmailId]);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${BASE_URL}/api/deleteDes/${id}`);
            toast.success("Destination deleted successfully", {
                autoClose: 3000,
                onClose: () => {
                  setDesData((prevData) => prevData.filter((des) => des && des._id !== id));
                  window.location = "/";
                },
              });
        } catch (error) {
            toast.success('Failed to delete destination', {
                autoClose: 3000
              });
            console.error("Failed to delete destination:", error.message);
        }
    };

    return (
        <div>
            <ToastContainer />
            <h2>My Destinations</h2>
            {console.log(" desEmail. loginEmail: usertype: ", props.desEmailId, props.userType, props.logInEmail)}

            {loading ? ( // Display loading spinner while data is being fetched
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    {rows.length > 0 ? (
                        <>
                        <Row xs={1} md={2} className="g-4">
                            {rows.map((des) => (
                                des !== null && (
                                    <Col key={des._id}>
                                        <div>
                                            <DestinationCard
                                                detail={des.detail}
                                                destination={des.destination}
                                                day={des.day}
                                                night={des.night}
                                                date={des.date}
                                                modeOfConv={des.modeOfConv}
                                                groupSize={des.groupSize}
                                                budget={des.budget}
                                                showDelete={props.logInEmail === props.desEmailId}
                                                handleDelete={() => handleDelete(des._id)}
                                            />
                                        </div>
                                    </Col>
                                )
                            ))}
                        </Row>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"20px" }}>
          <button disabled={currentPage<1} onClick={() => handlePageChange(currentPage-1)}>&lt;</button>
          {pageIndex.slice(
            Math.max(0, currentPage-2),
            Math.min(numberOfPage, currentPage+3)
          )
          .map((page)=>(
            <button
            key={page}
            onClick={()=>handlePageChange(page-1)}
            className={page===currentPage+1?"active":""}
            >
              {page}
            </button>
          ))
          }
          <button disabled={currentPage>numberOfPage} onClick={() => handlePageChange(currentPage+1)}>&gt;</button>
        </div>
        </>
                    ) : (
                        <h3>No Destinations found.</h3>
                    )}
                </>
            )}
        </div>
    );
}

export default MyDestination;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/esm/Button';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import "../css/style.css";
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

//const BASE_URL = "http://127.0.0.1:8080";
//const BASE_URL = "https://globecompanionbackend.onrender.com";
const BASE_URL = "https://globe-comapnion.onrender.com";

function AllUser(props) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemPerPage = 4;
  const [currentPage, setCurrentPage] = useState(0);
  const rows = users.slice(
    currentPage*itemPerPage,
    (currentPage + 1) * itemPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const numberOfPage = Math.ceil(users.length / itemPerPage);
  const pageIndex = Array.from({length:numberOfPage}, (_, idx) => idx+1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/all`);
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to make request:", error.message);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/deleteUser/${id}`);
      toast.success('User Deleted successfully', {
        autoClose: 3000
      });
      const updatedUsers = users.filter(user => user._id !== id);
      setUsers(updatedUsers);
    } catch (error) {
      toast.error('Failed to delete user', {
        autoClose: 3000
      });
      console.error("Failed to delete user:", error.message);
    }
  };

  return (
    <div>
      <ToastContainer />
      <h2>ALL USERS</h2>
      {loading ? ( // Display loading spinner while data is being fetched
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
      {rows.length > 0 ? (
        <>
        <Row xs={1} md={2} className="g-4">
          {rows.map((user) => {
            console.log(user)
            return (
              <Col key={user._id}>
                <Card>
                  <Card.Body className="d-flex">
                    <Card.Img
                      variant="top"
                      src={user.photo !== " " ? `data:image/jpeg;base64, ${user.photo}` : `${process.env.PUBLIC_URL}/userImage/sample.jpg`}
                      alt="User Photo"
                      className="user-image"
                      style={{
                        height: '200px',
                        objectFit: 'cover',
                        objectPosition: '50% 20%',
                      }}
                    />

                    <div className="flex-column ml-3">
                      <Card.Title style={{ fontSize: '1.5em' }}>{user.userName}</Card.Title>
                      <Card.Text>
                        <span style={{ fontSize: '1.2em' }}>User Type:</span> {user.userType}
                        <br />
                        <span style={{ fontSize: '1.2em' }}>Name:</span> {user.firstName} {user.lastName}
                        <br />
                        <br />
                        
                        <Button as={Link} to={`/myDes/${user.email}`} className="btn-dark ml-2">
                          See Destinations
                        </Button>
                      </Card.Text>

                      {props.userType === 'admin' && (
                        <Button onClick={() => handleDelete(user._id)} className="red-button">
                          Delete User
                        </Button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"20px" }}>
          <button disabled={currentPage<1} onClick={() => handlePageChange(currentPage-1)}>&lt;</button>
          {pageIndex.slice(
            Math.max(0, currentPage-2),
            Math.min(numberOfPage, currentPage+3)
          )
          .map((page)=>(
            <button
            key={page}
            onClick={()=>handlePageChange(page-1)}
            className={page===currentPage+1?"active":""}
            >
              {page}
            </button>
          ))
          }
          <button disabled={currentPage>numberOfPage} onClick={() => handlePageChange(currentPage+1)}>&gt;</button>
        </div>
        </>
      ) : (
        <p>No users found.</p>
      )}
      </>
      )}
    </div>
  );
}

export default AllUser;

import {Button, Form} from 'react-bootstrap';
import "../css/style.css";
import axios from "axios";
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OTPInput from 'otp-input-react'; 
import {CgSpinner} from "react-icons/cg"
import "react-phone-input-2/lib/style.css";

const BASE_URL = "https://globe-comapnion.onrender.com";

function ForgotPass() {

  const [data, setData] = useState({
      email: "",
      password: ""
  });

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
};
  
  const [error, setError] = useState('');
  const [otp, setOtp] = useState('');  
  const [otpGenerated, setOtpGenerated] = useState('');
  const [loading, setLoading] = useState(false); // Initial loading state set to false

  const sendOtpToEmail = async () => {
    try {
      setLoading(true); // Set loading to true before sending OTP
      const url = `${BASE_URL}/api/sendOtp`;
      const { data: res } = await axios.post(url, { email: data.email});

      setOtpGenerated(res.otp);
      toast.success('OTP sent successfully to your email', { autoClose: 3000 });
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Error sending OTP', { autoClose: 3000 });
    } finally {
      setLoading(false); // Set loading to false after OTP is sent
    }
  };

  const verifyOtp = async () => {
    try {
      setLoading(true); // Set loading to true before verifying OTP
      if (otpGenerated === otp) {
        toast.success('OTP verified successfully', { autoClose: 3000 });
      } else {
        toast.error('Incorrect OTP', { autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('Error verifying OTP', { autoClose: 3000 });
    } finally {
      setLoading(false); // Set loading to false after OTP is verified
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true); // Set loading to true before submitting password reset
      const url = `${BASE_URL}/api/forgotPass`;
      const { data: res } = await axios.post(url, data);
      toast.success('Registered successfully', {
        autoClose: 3000,
        onClose: () => {
          window.location = "/";
        },
      });
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
      }
    } finally {
      setLoading(false); // Set loading to false after submitting password reset
    }
  };

  return (
    <div  style={{margin:"15px"}}>
      <h2>RESET PASSWORD</h2>

      <ToastContainer />
      <Form className="Profile" onSubmit={handleSubmit}>

        {/* email */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            onChange={handleChange}
            value={data.email}
            required
          />
          <Button onClick={sendOtpToEmail} style={{marginTop:"0.5rem"}} className='btn-dark'>
            <span>Send OTP to Email</span>
          </Button>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicOTP">
          <Form.Label>OTP</Form.Label>
          <OTPInput
            value={otp}
            onChange={setOtp}
            OTPLength={6}
            otptype="number"
            disabled={false}
            autoFocus
          ></OTPInput>
          <Button onClick={verifyOtp} style={{marginTop:"0.5rem"}} className='btn-dark'>
            {loading ? ( // Display spinner if loading
              <CgSpinner size={20} className="mt-1 animate-spin" />
            ) : (
              <span>Verify OTP</span>
            )}
          </Button>
        </Form.Group>

        {/* pass */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            value={data.password}
            required
          />
        </Form.Group>

        {error && <div style={{color:"red"}}>{error}</div>}
        
        <Button variant="primary" type="submit" className='btn-dark'>
          {loading ? ( // Display spinner if loading
            <CgSpinner size={20} className="mt-1 animate-spin" />
          ) : (
            <span>Confirm Reset</span>
          )}
        </Button>
      </Form>
    </div>
  );
}

export default ForgotPass;

import { Route, Routes, useParams} from "react-router-dom";
import Home from './pages/Home';   
import Footer from './components/Footer';
import RegComp from './pages/RegComp';
import LoginComp from "./pages/LoginComp";
import Header from './components/Header';
import AllUser from "./pages/AllUser";
import Dashboard from "./pages/Dashboard";
import Destination from "./pages/Destination";
import AllDestination from './pages/AllDestination'; 
import MyDestination from './pages/MyDestination';
import FilterDes from "./components/FilterDes";
import ForgotPass from "./pages/ForgotPass";
import UpArrow from "./components/UpArrow";
import FilterModeOfConv from "./components/FilterModeOfConv";
import SortDes from "./components/SearchBy";
import SortModeOfConv from "./components/SortModeOfConv"

function App() {
  const user = localStorage.getItem("token");
  const emailId = localStorage.getItem("emailId");
  const userType = localStorage.getItem("userType");
  const userContact = localStorage.getItem("userContact");
  
  return (
    <>
    <div style={{
      backgroundImage: `url('https://img.freepik.com/premium-photo/simple-abstract-geometric-pattern-background-color-background-website-design-decoration_86390-7411.jpg?w=1000')`,
      backgroundColor: "#ffffff",
    }}>

    <Header 
      isLoggedIn = {user? true: false}
      userType = {userType}
      emailId = {emailId}
    />

    <Routes>
      <Route path="/" element={<Home isLoggedIn = {user? true: false} emailId={emailId}/>} />
      <Route path="/login" element={<LoginComp />} />
      <Route path="/register" element={<RegComp />} />
      <Route path="/allUser" element={<AllUser userType = {userType}/>} />
      <Route path="/dashboard" element={<Dashboard  emailId={emailId} userType={userType}/>} />
      <Route path="/addDestination" element={<Destination   emailId={emailId} userContact={userContact}/>} />
      <Route path="/allDes" element={<AllDestination emailId={emailId} isLoggedIn = {user? true: false}/>} />
      <Route path="/myDes/:desEmailId" element={<MyDesWrapper emailId={emailId} />} />
      <Route path="/filter/:des" element={<FilterWrapper />} />
      <Route path="/filterMode/:modeOfConv" element={<FilterModeWrapper />} />
      <Route path="/sortDes" element={<SortDes />} />      
      <Route path="/sortModeOfConv" element={<SortModeOfConv />} />      
      <Route path="/forgotPass" element={<ForgotPass />} />      
    </Routes>
    <Footer />
    <UpArrow />
    </div>
    </>
  );
}

function FilterWrapper() {
  const { des } = useParams();
  console.log("des in app.js: ", des)
  return <FilterDes selectedOption={des} />;
}

function FilterModeWrapper() {
  const { modeOfConv } = useParams();
  return <FilterModeOfConv selectedOption={modeOfConv} />;
}

function MyDesWrapper({ emailId}) {
  const { desEmailId } = useParams();
  return <MyDestination desEmailId={desEmailId} logInEmail={emailId}/>;
}

export default App;
import React, { useState, useEffect } from 'react';
import AllDestination from "./AllDestination";
import Features from '../components/Features';
import Review from '../components/Review';
import { Spinner } from 'react-bootstrap';

function Home(props) {
  const [loading, setLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    // Simulating loading delay for 2 seconds
    const timer = setTimeout(() => {
      setLoading(false); // Set loading state to false after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup function to clear timer on unmount
  }, []);

  const backgroundStyle = {
    backgroundImage: 'url("https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  };
  
  const overlayStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };
  
  return (
    <>
    {loading ? ( // Display loader while loading is true
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Spinner animation="border" variant="dark" />
      </div>
    ) : (
      <>
        <div style={backgroundStyle}>
          <div style={overlayStyle}>
            <div className="px-4 py-5 text-center">
              <h1 className="display-5 fw-bold" style={{color: "white"}}>GlobeCompanions</h1>
              <div className="col-lg-6 mx-auto">
                <p className="lead mb-4 fw-bold" style={{ fontSize:"25px"}}> Where Strangers Become Friends on a Journey of Shared Experiences</p> 
              </div>
            </div>
          </div>
        </div>

        <Features />

        <AllDestination isLoggedIn={props.isLoggedIn} emailId={props.emailId} />

        <Review />
      </>
    )}
    </>
  );
}

export default Home;

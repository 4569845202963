import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Row, NavDropdown, Spinner } from 'react-bootstrap';
import '../css/style.css';
import '../css/AllDestination.css'; 
import MessageModal from '../components/MessageModal';
import { toast } from 'react-toastify';
import SearchBy from '../components/SearchBy';
import SortModeOfConv from "../components/SortModeOfConv";
import DestinationCard from '../components/DestinationCard';
//const BASE_URL = "http://127.0.0.1:8080";
//const BASE_URL = "https://globecompanionbackend.onrender.com";
const BASE_URL = "https://globe-comapnion.onrender.com";

function AllDestination(props) {
  
  const itemPerPage = 4;
  const [dests, setDests] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const rows = dests.slice(
    currentPage*itemPerPage,
    currentPage + 1 * itemPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const numberOfPage = Math.ceil(dests.length / itemPerPage);
  const pageIndex = Array.from({length:numberOfPage}, (_, idx) => idx+1)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/allDes`);
        setDests(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to make request:", error.message);
      }
    };
    fetchData();
  }, []);

  //connect
  const handleContact = (id) => {
    if(props.isLoggedIn){
      setSelectedDestination(id);
      setShowModal(true);
    }
    else{
      toast.error('Login first', {
        autoClose: 3000
      });
    }
  };

  const handleSendMessage = async (message) => {
    console.log(`Sending message "${message}" to destination with ID: ${selectedDestination}, sent by ${props.emailId} `);
    try{
      await axios.post(`${BASE_URL}/api/sendMessage/${selectedDestination}/${props.emailId}/${message}`);
      toast.success('Message sent successfully', {
        autoClose: 3000
      });
      handleCloseModal();
    }catch(error){
      toast.error('Error in sending message', {
        autoClose: 3000
      });
    }
    
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDestination(null);
  };

  return (
    <div>
      <h2 className="text-center">DESTINATIONS</h2>
      {loading ? ( // Display loading spinner while data is being fetched
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
      <NavDropdown style={{ marginLeft: '30px', fontSize:'20px' }}
        id="nav-dropdown-dark-example"
        title={<> Sort By</>}
        menuVariant="light"
        align="start"
        marginLeft="0px"
        fontSize="10px"
      >
      <SearchBy />
      <SortModeOfConv />
  </NavDropdown>
      {rows.length > 0 ? (
        <>
        <Row className="gx-4 gy-4" style={{ margin: '15px' }}>
          {rows.map((des) => (
            <Col key={des._id} md={6}>
              <div>
              <DestinationCard
                  detail = {des.detail}
                  destination={des.destination}
                  day={des.day}
                  night={des.night}
                  date={des.date}
                  modeOfConv={des.modeOfConv}
                  groupSize={des.groupSize}
                  budget={des.budget}
                  showContact={props.logInEmail === props.desEmailId}
                  handleContact={() => handleContact(des._id)}
              />
              </div>
            </Col>
          ))}
        </Row>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"20px"  }}>
          <button disabled={currentPage<1} onClick={() => handlePageChange(currentPage-1)}>&lt;</button>
          {pageIndex.slice(
            Math.max(0, currentPage-2),
            Math.min(numberOfPage, currentPage+3)
          )
          .map((page)=>(
            <button
            key={page}
            onClick={()=>handlePageChange(page-1)}
            className={page===currentPage+1?"active":""}
            >
              {page}
            </button>
          ))
          }
          <button disabled={currentPage>numberOfPage} onClick={() => handlePageChange(currentPage+1)}>&gt;</button>
        </div>
        </>
      ) : (
        <p className="text-center">No destinations found.</p>
      )}

      <MessageModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleSendMessage={handleSendMessage}
      />
      </>)}
    </div>
  );
}

export default AllDestination;

import React from 'react';
import { IoAirplaneOutline, IoPeopleOutline, IoLockClosedOutline } from 'react-icons/io5';

function Review(){

  const featureBoxStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    height: '100%',
  };

  return (
    <>
    <div className="container px-4 py-5 mx-8" id="featured-3">
      <h2 className="pb-2 border-bottom">REVIEWS</h2>
    <div className="row g-4 row-cols-1 row-cols-lg-3 m-2">
          <div className="feature col">
            <div className="feature-box" style={featureBoxStyle}>
            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-dark bg-gradient fs-2 mb-3">
                <IoAirplaneOutline size="1.5em" />
              </div>
              <h3 className="fs-2 text-body-emphasis">Transformative Travel Experiences with GlobeCompanion</h3>
              <p>Using GlobeCompanion has been a game-changer for my travel experiences. As someone who loves exploring new destinations but occasionally faces challenges finding a travel partner, this platform has become my go-to solution. </p>
            </div>
          </div>
      <div className="feature col">
      <div className="feature-box" style={featureBoxStyle}>
      <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-dark bg-dark fs-2 mb-3">
                <IoPeopleOutline size="1.5em" />
              </div>
        <h3 className="fs-2 text-body-emphasis">Building Connections and Making Memories with GlobeCompanion</h3>
        <p>GlobeCompanion has truly revolutionized the way I travel. It's not just a platform; it's a community of passionate individuals eager to explore the world together. I've met incredible people who share the same wanderlust, and we've embarked on journeys that I wouldn't have experienced alone. </p>
      </div>
      </div>
      <div className="feature col">
      <div className="feature-box" style={featureBoxStyle}>
      <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-dark bg-gradient fs-2 mb-3">
                <IoLockClosedOutline size="1.5em" />
              </div>
        <h3 className="fs-2 text-body-emphasis">Exploring the World Safely and Socially with GlobeCompanion</h3>
        <p>Discovering GlobeCompanion has added a whole new dimension to my travel adventures. Whether it's finding a companion for a spontaneous weekend getaway or planning a more extended trip, this platform has been a lifesaver. </p>
      </div>
      </div>
    </div>
    </div>
    </>
  );
}

export default Review;

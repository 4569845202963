import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const MessageModal = ({ showModal, handleCloseModal, handleSendMessage }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    handleSendMessage(message);
    setMessage('');
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Send Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Type your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSend}>
          Confirm Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;

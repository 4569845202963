import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/style.css";
import "../css/destination.css";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCalendarAlt } from 'react-icons/fa';

const BASE_URL = "https://globe-comapnion.onrender.com";

function Destination(props) {
    const [data, setData] = useState({
        detail: "",
        destination: "",
        day: "",
        night: "",
        date: null,
        modeOfConv: "",
        groupSize: "",
        budget: ""
    });
    const [loading, setLoading] = useState(false); // Initial loading state set to false

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name === 'destination') {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }

        setData({ ...data, [name]: value });
    };

    const handleDateChange = date => {
        setData({ ...data, date: date });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true); // Set loading to true before making the request

            const url = `${BASE_URL}/api/addDes/${props.emailId}/${props.userContact}`;
            const { data: res } = await axios.patch(url, data);

            toast.success("Destination added successfully", {
                autoClose: 3000,
                onClose: () => {
                    window.location = "/";
                },
            });

        } catch (error) {
            toast.error('Failed to add destination', {
                autoClose: 3000
            });
            console.log("Error: ", error);
        } finally {
            setLoading(false); // Set loading to false after the request completes
        }
    };

    return (
        <>
            <h2>ADD DESTINATIONS</h2>
            {loading ? ( // Display loading spinner while data is being fetched
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    <ToastContainer />
                    <Form onSubmit={handleSubmit} style={{ margin: "15px" }}>

                        <Form.Group as={Col} controlId="formGridText">
                            <Form.Label>Destination</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Destination"
                                name="destination"
                                onChange={handleChange}
                                value={data.destination}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridText">
              <Form.Label>Additional Details</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Details"
                name="detail"
                onChange={handleChange}
		            value={data.detail}
                />
            </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridText">
              <Form.Label>Duration of trip</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="days in number"
                name="day"
                onChange={handleChange}
		            value={data.day}
                />
              
              <Form.Control
                required
                type="text"
                placeholder="night in number"
                name="night"
                onChange={handleChange}
		            value={data.night}
                />
            </Form.Group>
            
            <Form.Group as={Col} md={6} controlId="formGridDate">
            <Form.Label>Date</Form.Label>
            <div style={{ position: 'relative' }}>
              <DatePicker
                selected={data.date}
                onChange={(date) => handleDateChange(date)}
                dateFormat="MM/dd/yyyy"
                placeholder="Choose Date"
                className="form-control"
              />
              <FaCalendarAlt style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }} />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridText">
              <Form.Label>Mode of convenience</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Mode of convenience"
                name="modeOfConv"
                onChange={handleChange}
		            value={data.modeOfConv}
                />
            </Form.Group>

            <Form.Group as={Col} md={6} controlId="formGridDate">
            <Form.Label>Group Size</Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="max group size in number"
                name="groupSize"
                onChange={handleChange}
		            value={data.groupSize}
                />
          </Form.Group>

          <Form.Group as={Col} md={6} controlId="formGridDate">
            <Form.Label>Budget</Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="in rupees"
                name="budget"
                onChange={handleChange}
		            value={data.budget}
                />
          </Form.Group>
        </Row>

          <Form.Group className="mb-3" id="formGridCheckbox">
            <Form.Check type="checkbox" label="Confirm" />
          </Form.Group>

                        <Button variant="primary" type="submit" className="btn-dark">
                            Submit
                        </Button>
                    </Form>
                </>
            )}
        </>
    );
}

export default Destination;

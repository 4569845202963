import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//const BASE_URL = "http://127.0.0.1:8080";
//const BASE_URL = "https://globecompanionbackend.onrender.com";
const BASE_URL = "https://globe-comapnion.onrender.com";

function SearchBy() {
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${BASE_URL}/api/searchModeOfConv`);
              setSearchList(response.data);
          } catch (error) {
              console.error("Failed to make mode filter request:", error.message);
          }
        };
        fetchData();
    }, []);

    return(
        <div style={{ margin: "5px", marginBottom:"-7px"  }}>
        <NavDropdown
              id="nav-dropdown-dark-example"
              title={<span style={{ fontSize: "18px"}}>Mode of Convenience</span>}
              menuVariant="light"
              align="start"
              style={{fontSize:"20px"}}
            >
            {searchList.data && (searchList.data).length > 0 ? (
                (searchList.data).map((item, index) => (
                    <NavDropdown.Item
                        key={index}
                        as={Link} 
                        to={`/filterMode/${item}`}
                    >
                        {item}
                    </NavDropdown.Item>
                ))
            ) : (
                <p>No items to display</p>
            )}
        </NavDropdown>
        </div>
    );
}

export default SearchBy;